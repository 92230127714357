require('./src/styles/global.css')

const countryNames = require('i18n-iso-countries')
const de = require('i18n-iso-countries/langs/de.json')
const en = require('i18n-iso-countries/langs/en.json')
const es = require('i18n-iso-countries/langs/es.json')
const fr = require('i18n-iso-countries/langs/fr.json')
const it = require('i18n-iso-countries/langs/it.json')
const pt = require('i18n-iso-countries/langs/pt.json')

countryNames.registerLocale(de)
countryNames.registerLocale(en)
countryNames.registerLocale(es)
countryNames.registerLocale(fr)
countryNames.registerLocale(it)
countryNames.registerLocale(pt)

exports.onInitialClientRender = () => {
  let deferredStyles4 = document.createElement('link')
  deferredStyles4.href =
    'https://fonts.googleapis.com/css2?family=Hedvig+Letters+Serif:opsz@12..24&family=Inter:wght@300&display=swap'
  deferredStyles4.rel = 'stylesheet'
  let godefer4 = document.getElementsByTagName('link')[0]
  godefer4.parentNode.insertBefore(deferredStyles4, godefer4)

  let getQueryVariable = (variable) => {
    let query = window.location.search.substring(1)
    let vars = query.split('&')
    for (let i = 0; i < vars.length; i += 1) {
      let pair = vars[i].split('=')
      if (pair[0] === variable) {
        return pair[1]
      }
    }
    return false
  }

  let parseReferrer = (url) => {
    if (url === '') return false
    let a = document.createElement('a')
    a.href = url
    return a.hostname
  }

  // https://stackoverflow.com/questions/5639346/what-is-the-shortest-function-for-reading-a-cookie-by-name-in-javascript
  function cookieExists(a) {
    let b = document.cookie.match('(^|[^;]+)\\s*' + a + '\\s*=\\s*([^;]+)')
    return b ? b.pop() : false
  }

  // https://stackoverflow.com/questions/14573223/set-cookie-and-get-cookie-with-javascript
  function setCookie(name, value, days) {
    let expires = ''
    if (days) {
      let date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = '; expires=' + date.toUTCString()
    }
    document.cookie =
      name + '=' + (value || '') + expires + '; domain=myphoner.com; path=/'
  }

  let initCookies = () => {
    let ref = getQueryVariable('ref')
    let src = getQueryVariable('src')
    let referrer = parseReferrer(document.referrer)

    if (ref) {
      setCookie('affiliate', ref, 30)
    }
    if (src && !cookieExists('myphoner_source')) {
      setCookie('myphoner_source', src, 90)
    }
    if (!cookieExists('myphoner_referrer')) {
      setCookie('myphoner_referrer', referrer, 30)
    }
  }

  initCookies()
}
